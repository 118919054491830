import React from "react";
import styled, { keyframes }  from "styled-components";
import { DialogContent} from "../FoodDialog/FoodDialog";
import { getPrice } from "../FoodDialog/FoodDialog";
import { useState, useEffect } from "react";
import { SignIn_Up } from "./SignIn_Up";
import { PromoCode } from "./PromoCode";
import { OrderBtn } from "../Styles/colors";
import { Title } from "../Styles/title";
import {T} from "../Language/translate";
import { UserDialog } from "../userDialog/userDialog";
const OrderStyled = styled.div`
  position: fixed;
  
  right: 0px;
  top: 50px;
  width: 340px;
  background-color: white;
  height: calc(100% - 50px);
  z-index: 10;
 
  display: flex;
  flex-direction: column;
 
  // Add this part
  @media(max-width: 700px){
    display: ${props => props.display}; 
    position: fixed; 
    width: 100%; 
  }
`;
const DialogFooter = styled.div`
box-shadow : 0px -2px 10px 0px grey;
height: 55px;
display: flex;
justify-content: center;
border-radius:5px;
`;
const ConfirmButton = styled.button`
font-family: 'Righteous',cursive;
margin: 10px;
color : white;
height:40px;
width:150px;
border-radius: 5px;
padding: 10px;
text-align: center;
cursor: pointer;
background-color: ${OrderBtn};
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;


const BasketBtn = styled.div`
 position:fixed;
 height: 30px;
 width: 30px;
 top:12px;
 right:3px;
 z-index:30;
 margin-right:10px;
 margin-top:8px;
 background-image:   ${props => props.url}; 
 background-position: center;
 background-repeat: no-repeat;
 background-size: cover;
 margin-left:20px;

 cursor: pointer;
 @media(min-width: 700px){ 
    
    display:none;
    
  }
  @media(max-width: 700px){ 
   
 
    
  }

`;
const BasketNotif = styled.div`

position:relative;
height: 20px;
 width: 20px;
 background-color:#F8F1DE;
 border-radius:50%;
  box-shadow : 0px 0px 5px 0px gray;
 top:-15%;
 left:-10%;
 justify-content:center;
 display: ${props => props.display}; 
align-items: center;
 text-align: center;
`;
const OrderContent = styled(DialogContent)`
height: 100%;

padding: 10px;
// Add this part
@media(max-width: 700px){ 
  
  padding-top:10px;
  
}
`;
const Stripe = styled.div`
width:100%;
height:100%;
background-color:#f44336;
padding-top:5px;
padding-bottom:5px;
color:white;
text-align:center;
padding-top:3px;
font-weight:bold;
`;
const Open = styled.div`
width:100%;
background-color:green;
padding-top:10px;
padding-bottom:5px;
color:white;
text-align:center;
padding-top:3px;
font-weight:bold;

`;
const FooterContainer = styled.div`
width:100%;

`;
const OrderContentHeader = styled(DialogContent)`
height: 100%;
text-align:center;
padding: 20px;
// Add this part
@media(max-width: 700px){ 
  text-align:center;
  padding-top:50px;
  
}
`;
const Text = styled.div`
margin-top:15px;
font-size:22px;
color:white;
animation: ${blinkingEffect} 3s linear infinite;
`;
function blinkingEffect(){
    return keyframes`
    50% {
      color:transparent;
    
    }`;
  }
const OrderContainer = styled.div`
       padding: 10px 0px;
       border-bottom: 1px solid grey;
       ${
           ({editable})=> editable ? `
           &:hover {
               cursor: pointer;
               background-color: #e7e7e7;
           }`
           :`
           pointer-events: none; `
       }
`;
const ButtonOrderContainerH = styled.div`
display:flex;
flex-direction:row;
`;

const OrderItem = styled.div`
padding : 10px 0px ; 
display : grid;
grid-template-columns: 150px 20px 60px 15px;
justify-content: space-between ; 

${({disabled})=>disabled && `
opacity: .5;
cursor: not-allowed;
pointer-events: none;
`}

`;
const PromoCont = styled.div`
padding : 10px 0px ; 
display : grid;
grid-template-columns: 1fr 1fr ;
justify-content: space-between ; 

`;
const DetailItem = styled.div`
color: gray;
font-size: 11px;
`
const Div = styled.div`
white-space: nowrap !important;
font-size:15px;
`;
const Diam = styled.div`
position:relative;
white-space: nowrap;
`;
const Btn = styled.button`
height:38px;
width:auto;
margin:auto;
`;
export const Notice = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: green;
  font-size: 16px;
  font-weight:600;
`;
const Notice2 = styled.span`
white-space: nowrap;
  margin-left:20px;
  color: green;
  font-size: 14px;
  font-weight:300;
`;
const Delete = styled.div`
margin-left :auto;
cursor:pointer;
${({disabled})=>disabled && `
opacity: .5;
cursor: not-allowed;
pointer-events: none;
`}
`;

const Qu = styled.div`
font-size:14px;
white-space: nowrap;
`;
const Hide = styled.div`
display:none;
`;
const Span = styled.div`
margin:auto;
`;
const XBtn = styled.button`
font-size:20px;
align-items:center;
justify-content:center;
margin: 10px;
color : white;
height:40px;
width:60px;
border-radius: 5px;
text-align: center;
cursor: pointer;
background-color: ${OrderBtn};
opacity:0.8;
z-index : 4;
${({disabled})=>disabled && `
opacity: .5;
background-color: grey;
pointer-events: none;
`}
`;
const TextFlex = styled.div`
margin-top:10px;
text-align:center;
animation: ${blinkingEffect} 3s linear infinite;

`;
const SideBanner = styled.div`
position: relative;

height: 100%;
width :100%;
background-image: url("/img/sideBanner.jpg");

background-repeat: no-repeat;
background-size: 340px 100%;
@media(max-width: 700px){ 
  
  background-size: 100% 100%;
  
}
`;

const useCurrentDateTime = () => {
  const [currentDateTime, setCurrentDateTime] = useState(new Date());

  useEffect(() => {
    const fetchCurrentDateTimeFrance = async () => {
      try {
        const apiKey = '348M8JPBF5B1'; // Replace with your TimezoneDb API key
        const response = await fetch(`http://api.timezonedb.com/v2.1/get-time-zone?key=${apiKey}&format=json&by=zone&zone=Europe/Paris`);
        const data = await response.json();

        if (data && data.formatted) {
          const dateTimeString = data.formatted;
          const dateTime = new Date(dateTimeString);
          setCurrentDateTime(dateTime);
        } else {
          throw new Error('Unable to fetch current date and time in France.');
        }
      } catch (error) {
        console.error('Error:', error.message);
        // Handle error accordingly
      }
    };

    fetchCurrentDateTimeFrance();
  }, []);

  return currentDateTime;
};







     const isActive =true;
     const db = window.firebase.firestore();
     var _ = require('lodash');
     var session = window.sessionStorage;
export function Order({orders, setOrders, setOpenFood, loginWithGoogle, loggedIn, setTotal,deliveryFee,setLogInPanel,setSignUpPanel,basket,
  setBasket,setPromoPrice,setDeliveryFee,lang,setLimitCode,applied,setApplied,
  isGuest,setIsGuest,openUser,setOpenUser,crossSell,setCrossSell,setOpenOrderDialog,setPromoCategory,
  promoCategory,setIndexOfCheap,admins,setOrderType,setOrderEdit,minOrderPrice,
  setMinOrderPrice}){
      var currentDeliveryFee =0
      if(currentDeliveryFee < deliveryFee) currentDeliveryFee =  deliveryFee ;
      const[working,setWorking] = useState();
      const[interval,setInterval] = useState();
      const[startTime, setStartTime] = useState();
      const[endTime, setEndTime] = useState();
      const[startTime2, setStartTime2] = useState();
      const[endTime2, setEndTime2] = useState();
      const[code, setCode] = useState();
      const[discountCodes,setDiscountCodes]=useState();
      const[discountValue,setDiscountValue]=useState(0);
      const[discountType,setDiscountType]=useState("");
      const[message,setMessage]=useState("");
      const[promoItems, setPromoItems] = useState();
      const[global,setGolobal]=useState(true);
      const[text,setText]=useState(T("8",lang));
    
       const[packagingFee,setPackagingFee]= useState(0.5);
      const[changeRate,setChangeRate]=useState(0);
      const[deliveryPromo,setDeliveryPromo]=useState(1);
      const[promo,setPromo] = useState();
      const[p2index, setP2index] = useState();
      var pindex;
      
         const subtotal = orders.reduce((total, order)=>{
               return total+ getPrice(order);
         },0);
          
    
         const[signIn_UpPanel,setSignIn_UpPanel] = useState(false);    
        
         const currentDateTime = useCurrentDateTime();
  
    

         




     const canShowWork = working && true;
    useEffect(()=>{ 
        const today = Number(new Date().getDay());
        var codes;
        var items;
        db.collection('offer').onSnapshot((data)=>{
           codes = _.toArray(data.docs);
          setDiscountCodes(codes)});
      
          db.collection('items').get().then((data)=>{
            items = _.toArray(data.docs);
           setPromoItems(items)});


        db.collection('schudel').where('index','==',today).onSnapshot((data)=>{return  setWorking(data.docs[0].data().work)});
        db.collection('schudel').doc("7").onSnapshot((data)=>{return  setInterval(data.data().inter)});
        db.collection('schudel').where('index','==',today).onSnapshot((data)=>{return  setStartTime(data.docs[0].data().startTime)});
        db.collection('schudel').where('index','==',today).onSnapshot((data)=>{return  setEndTime(data.docs[0].data().endTime)});

        db.collection('schudel').where('index','==',today).onSnapshot((data)=>{return  setStartTime2(data.docs[0].data().startTime2)});
        db.collection('schudel').where('index','==',today).onSnapshot((data)=>{return  setEndTime2(data.docs[0].data().endTime2)});


        db.collection('Fees').where('name','==',"Standard").onSnapshot((data)=>{return  setPackagingFee(Number(data.docs[0].data().packagePrice))});
        db.collection('Fees').where('name','==',"Standard").onSnapshot((data)=>{return  setChangeRate(data.docs[0].data().rate)});

        db.collection('delivery').where('name','==',"delivery").onSnapshot((data)=>{ setDeliveryPromo(data.docs[0].data().free ? 1 :Number(data.docs[0].data().percentage) ===0||!(data.docs[0].data().startTime<(((new Date().getHours()>=0 && new Date().getHours()<=9) ? "0"+new Date().getHours(): new Date().getHours())+":"+new Date().getMinutes()) && data.docs[0].data().endTime >(((new Date().getHours()>=0 && new Date().getHours()<=9) ? "0"+new Date().getHours(): new Date().getHours())+":"+new Date().getMinutes())) ? 0 : Number(data.docs[0].data().percentage)/100)});
        db.collection('delivery').where('name','==',"delivery").onSnapshot((data)=>{ setText(Number(data.docs[0].data().percentage) === 0 ||!(data.docs[0].data().startTime<(((new Date().getHours()>=0 && new Date().getHours()<=9) ? "0"+new Date().getHours(): new Date().getHours())+":"+new Date().getMinutes()) && data.docs[0].data().endTime >(((new Date().getHours()>=0 && new Date().getHours()<=9) ? "0"+new Date().getHours(): new Date().getHours())+":"+new Date().getMinutes()))? setPromo('no') : setPromo('yes'))});


     
        },[]);
       

      


        const isAdmin = loggedIn&& admins.some(u=>u.email ===loggedIn.email&&(u.role==="admin"||u.role==="pos"));
        //Global code test 
        var globalCode = discountCodes && discountCodes.filter((item)=>item.data().code=="global").length >=1?discountCodes.filter((item)=>item.data().code=="global")[0].data(): [];
        var ordersItems =[];
        var ordersCategory =[];
        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
       
        let globalTime = !globalCode.recrusive ? ((new Date(globalCode.startDate).getTime()/1000)< new Date().getTime()/1000&&(new Date(globalCode.endDate).getTime()/1000)> new Date().getTime()/1000):globalCode.startDate.substring(11,16)<(new Date().getHours() +":"+ new Date().getMinutes()) &&globalCode.endDate.substring(11,16)>(new Date().getHours() +":"+ new Date().getMinutes());
        if(globalCode&&globalTime &&global){
           
          if(globalCode.type =="peritem"){
            var test = ordersCategory.filter((item)=>item===globalCode.category)
            if(test.length >= Number(globalCode.quantity)){
          
            var Elmnt;
            Elmnt = promoItems.filter((item)=>item.data().name == globalCode.items[0] )[0].data();
              
            const order = {
              ...Elmnt,
              price:0,
              toppings: [],
              Qu: 1
             
          };
          
          
         setOrders([...orders, order]);
         setGolobal(false);
         ordersItems =[];
         ordersCategory =[];
        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });

          }
        }


          if(globalCode.type =="perquantity"){
            var test = ordersCategory.filter((item)=>item==globalCode.category)
           
            if(test.length >= Number(globalCode.quantity)){
              setPromoPrice(Number(globalCode.value));
              setPromoCategory(globalCode.category);
              ordersItems =[];
              ordersCategory =[];
             orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
             orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
            
          }
        }


          if(globalCode.type =="perdelivery"){
              var test = ordersCategory.filter((item)=>item==globalCode.category)
            if(test.length >= Number(globalCode.quantity)){
              
              setDeliveryFee(deliveryFee-(deliveryFee*Number(globalCode.value)/100));
              setText(T("8",lang)+globalCode.value+"%");
              setGolobal(false);
              ordersItems =[];
              ordersCategory =[];
             orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
             orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });

            }
          }
        }



        function deleteItem(index) {
          setIndexOfCheap();
           ordersItems =[];
           ordersCategory =[];
          orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
          orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
          setPromoPrice(0)
           
            const newOrders = [...orders];
            const newOrders2=[...orders];
            const indQu = newOrders[index].Qu;
               //one element of the orders 
               const docRef = db.collection("items").where("name","==",newOrders2[index].category==="Pizzák" ?newOrders2[index].name.slice(0,5):newOrders2[index].name);
               docRef.get().then((querySnapshot)=>{
                querySnapshot.forEach((doc)=>{
                 doc.ref.update({
                   quantity:  doc.data().quantity + indQu
                 })
                })
              });
                /*****/
           newOrders.splice(index, 1);
         


            // promo elem remove
            console.log(pindex)
            console.log(newOrders2)

            if(pindex){
              console.log(newOrders2[pindex-1].category==="Pizzák" ?newOrders2[pindex-1].name.slice(6):newOrders2[pindex-1].name)
              const pindQu = newOrders2[pindex-1].Qu ;
              const docRef = db.collection("items").doc(newOrders2[pindex-1].category==="Pizzák" ?newOrders2[pindex-1].name.slice(6):newOrders2[pindex-1].name);
              docRef.get().then((data)=>{
              
                docRef.update({
                  quantity:  data.data().quantity + pindQu
                })
               });
              
              newOrders2.splice(pindex, 1);
              setOrders(newOrders2);
           
            }
            // promo elem remove

            if(p2index){ 
              if(p2index >=index){
                const p2indQu1 = newOrders[p2index-1].Qu ;
                const docRef = db.collection("items").doc(newOrders[p2index-1].category==="Pizzák" ?newOrders[p2index-1].name.slice(6):newOrders[p2index-1].name);
                docRef.get().then((data)=>{
                  docRef.update({
                    quantity:  data.data().quantity + p2indQu1
                  })
                 });

              }
              else{
                const p2indQu2 = newOrders[p2index].Qu ;
                const docRef = db.collection("items").doc(newOrders[p2index].category==="Pizzák" ?newOrders[p2index].name.slice(6):newOrders[p2index].name);
                docRef.get().then((data)=>{
                  docRef.update({
                    quantity:  data.data().quantity + p2indQu2
                  })
                 });
              }
              
              
              
              p2index >=index ?  newOrders.splice(p2index-1, 1):newOrders.splice(p2index, 1);
              setOrders(newOrders);
            }
            var objIndex = orders.findIndex((obj => obj.name.charAt(obj.name.length - 1) == "%" ));
          
            pindex=null;
            // promo elm rmv
    
            if(objIndex !=-1&& newOrders.length !=0){
              const objIndexQu = newOrders[objIndex].Qu ;
              const docRef = db.collection("items").doc(newOrders[objIndex].category==="Pizzák" ?newOrders[objIndex].name.slice(6):newOrders[objIndex].name);
              docRef.get().then((data)=>{
                docRef.update({
                  quantity:  data.data().quantity + objIndexQu 
                })
               });
              newOrders.splice(objIndex, 1);
            }
           setP2index();
         
            if(orders.length == 1) setGolobal(true);
            if(!applied &&orders.length!=1){
              setMessage(T("18",lang));
            
            }
            else{
              setMessage("");
            }
            setOrders(newOrders);
          
        }








        // Percentage 
        function Discount(){
          setMessage("");
          var promotionCodes=[];
          var ordersCategory =[];
        
                discountCodes.filter((item)=>item.data().code!="global").map((item)=>promotionCodes.push((item.data().code)));
                
                orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
              
             
      
                if(!promotionCodes.includes(code)){
                
                  setMessage(T("17",lang)); 
                   
                }
                if(!applied){
                  setMessage(T("16",lang));
                }

                if(promotionCodes.includes(code)&&applied){
                
                  var Element;
                    Element = discountCodes.filter((item)=>item.data().code == code );
               if( !Element[0].data().recrusive ? ((new Date(Element[0].data().startDate).getTime()/1000)< new Date().getTime()/1000&&(new Date(Element[0].data().endDate).getTime()/1000)> new Date().getTime()/1000)&& Number(Element[0].data().limit)> 0 :Element[0].data().startDate.substring(11,16)<(new Date().getHours() +":"+ new Date().getMinutes()) &&Element[0].data().endDate.substring(11,16)>(new Date().getHours() +":"+ new Date().getMinutes())&& Number(Element[0].data().limit)> 0 ){



                if(Element[0].data().type =="food"&& ordersItems.includes(Element[0].data().items[0]) ){
                  if(Element[0].data().valueType=="huf"){
                    setDiscountType(Element[0].data().valueType);
                
                         setDiscountValue(Number(Element[0].data().value));
                         setMessage(T("15",lang))
                         setApplied(false);
                         setGolobal(false);
                         //set limit code to excute later
                         setLimitCode(code);
                         ordersItems =[];
                         ordersCategory =[];
                        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
                        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
                          
                      
                  }

                  if(Element[0].data().valueType=="percentage"){
                   var objIndex = orders.findIndex((obj => obj.name ==Element[0].data().items[0] ));
                    orders[objIndex].price = orders[objIndex].price  -(orders[objIndex].price *Number(Element[0].data().value)/100)
                    orders[objIndex].name = orders[objIndex].name+" "+Element[0].data().value+"%";
                    setMessage(T("15",lang))
                         setApplied(false);
                         setGolobal(false);
                          //set limit code to excute later
                          setLimitCode(code);
                          ordersItems =[];
                          ordersCategory =[];
                         orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
                         orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
                  }

                }else if(Element[0].data().type =="food"&& !ordersItems.includes(Element[0].data().items[0]) ) {
                  setMessage(T("12",lang)+Element[0].data().items[0])
                  
                }

                else if(Element[0].data().type =="category"&& ordersCategory.includes(Element[0].data().items[0]) ){
                 
                  if(Element[0].data().valueType=="huf"){
          
                    setDiscountType(Element[0].data().valueType);
                 
                         setDiscountValue(Number(Element[0].data().value));
                         setMessage(T("15",lang))
                         setApplied(false);
                         setGolobal(false);
                        //set limit code to excute later
                        setLimitCode(code);
                        ordersItems =[];
                        ordersCategory =[];
                       orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
                       orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
                  }

                  if(Element[0].data().valueType=="percentage"){
                 
                    setDiscountType(Element[0].data().valueType);
                 
                    
                     // fixing discount to be applied only on the category name 
                     orders.forEach(elmnt =>{

                      if(Element[0].data().items[0]==elmnt.category){
                        elmnt.price = elmnt.price  -(elmnt.price *Number(Element[0].data().value)/100)
                      elmnt.name = elmnt.name+" "+Element[0].data().value+"%";
                      }
                      

                     })
                   

                         setMessage(T("15",lang))
                         setApplied(false);
                         setGolobal(false);
                         //set limit code to excute later
                         setLimitCode(code);
                         ordersItems =[];
                         ordersCategory =[];
                        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
                        orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
                    
                  }

                }else if (Element[0].data().type =="category"&& !ordersCategory.includes(Element[0].data().items[0]) ) {
                
                  setMessage(T("12",lang)+Element[0].data().items[0]+T("13",lang))
                
                }
                
              
               else if(Element[0].data().type =="perquantity"&& ordersCategory.includes(Element[0].data().category) ){
                
          var test = ordersCategory.filter((item)=>item==Element[0].data().category)
          if(test.length >= Number(Element[0].data().quantity)){
            setPromoPrice(Number(Element[0].data().value));
            setMessage(T("15",lang))
            setApplied(false);
            setGolobal(false);
            //set limit code to excute later
            setLimitCode(code);
            ordersItems =[];
            ordersCategory =[];
           orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
           orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
          
        }
        else{
          setMessage(T("14",lang))
        }

                }else if (Element[0].data().type =="perquantity"&& !ordersCategory.includes(Element[0].data().category) ) {
                  setMessage(T("12",lang)+Element[0].data().category+T("13",lang))
                
                }





             else if(Element[0].data().type =="perdelivery"&& ordersCategory.includes(Element[0].data().category) ){
                
          var test = ordersCategory.filter((item)=>item==Element[0].data().category)
          if(test.length >= Number(Element[0].data().quantity)){
            setDeliveryPromo(Number(Element[0].data().value)/100);
            setMessage(T("15",lang))
            setText(T("8",lang)+Element[0].data().value+"%");
            setApplied(false);
            setGolobal(false);
           //set limit code to excute later
            setLimitCode(code);
            ordersItems =[];
            ordersCategory =[];
           orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
           orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
          
        }
        else{
          setMessage(T("14",lang))
        }

                }else if (Element[0].data().type =="perdelivery"&& !ordersCategory.includes(Element[0].data().category) ) {
                  setMessage(T("12",lang)+Element[0].data().category+T("13",lang))
                
                }







                
             else if(Element[0].data().type =="peritem"&& ordersCategory.includes(Element[0].data().category) ){
           
      var test = ordersCategory.filter((item)=>item==Element[0].data().category)
      if(test.length >= Number(Element[0].data().quantity)){
       setP2index(orders.length);

             
        var Elmnt;
        Elmnt = promoItems.filter((item)=>item.data().name == Element[0].data().items[0] )[0].data();
       
        const order = {
          ...Elmnt,
          price:0,
          toppings: [],
          Qu: 1
         
      };
      
     
     setOrders([...orders, order]);

     setMessage(T("15",lang))
      
        setApplied(false);
        setGolobal(false);
       //set limit code to excute later
        setLimitCode(code);
        ordersItems =[];
        ordersCategory =[];
       orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersItems.push(item.name)} });
       orders.map((item)=>{for(let i=0; i<item.Qu; i++){ordersCategory.push(item.category)} });
      
    }
    else{
      setMessage(T("14",lang))
    }

            }else if (Element[0].data().type =="perdelivery"&& !ordersCategory.includes(Element[0].data().category) ) {
              setMessage(T("12",lang)+Element[0].data().category+T("13",lang))
            
            }








               }
               else{
                  setMessage(T("11",lang));

               }
                 




                }
        }




        const totalPoints = orders.reduce((total, order)=>{
          return total+ Number(order.points); 
    },0);
  
    return (
        <>
      
        {signIn_UpPanel && <SignIn_Up  setSignIn_UpPanel={setSignIn_UpPanel} setLogInPanel={setLogInPanel} loginWithGoogle={loginWithGoogle} setSignUpPanel ={setSignUpPanel} lang={lang} setIsGuest={setIsGuest} openUser={openUser} setOpenUser={setOpenUser}/>}
        {  orders? "":""}
        <BasketBtn url={basket=="none"? 'url("/img/cart.png")':'url("/img/cross.png")'} onClick={()=>{basket =='none'?setBasket("flex"):setBasket('none')}}>
            <BasketNotif display={basket=="none" ?'flex':'none'} >{orders.length}</BasketNotif>
           <Hide>{discountCodes ? "":""}</Hide> 
           
        </BasketBtn>
       
      
    <OrderStyled display={basket}> 
 {orders.length=== 0 ?
      
          <SideBanner />
         
          
         
         : (
        <OrderContent> 
            {console.log("sub "+ subtotal + "   "+minOrderPrice)}
           
            <OrderContainer>{T("19",lang)} {subtotal >= minOrderPrice? "":<Notice>{T("9",lang)+" "+minOrderPrice+" €"} </Notice>}</OrderContainer>

            

            {" "}
            {orders.map((order, index) => (<OrderContainer  key={index} editable>
             <Hide> {!pindex ? order.name.charAt(order.name.length - 1) == "%"  ? pindex=index : pindex =null : '' } </Hide>
             {/* Fix the editing problem */}
             <OrderItem
             onClick={()=>{
                 setOpenFood({...order, index});
                 setOrderEdit(true);
             }}
             >
                 <Qu>{order.Qu+" x "+order.name} </Qu>
                 <div />
                 <div>{getPrice(order)+" €"}</div>
                 <Delete onClick={e =>{
                     e.stopPropagation();
                     deleteItem(index);setDiscountValue(0);
                     setApplied(true);
                     setDeliveryFee(currentDeliveryFee);
                     setDeliveryPromo(0);}}>❌</Delete>
                
             </OrderItem>
             {order.foodType  && <DetailItem>{order.foodType}</DetailItem>} 
             {order.category !== "Tacos : " && <DetailItem>{order.viande }</DetailItem>} 
             {console.log(order.selectedChoice)}
             {order.selectedChoice!== "Choix : "  && <DetailItem>{order.selectedChoice }</DetailItem>} 
             {(order.drinks !== "Boissons : "&&order.drinks !== "") && <DetailItem>{order.drinks }</DetailItem>} 
             
             {(order.sauces !== "Sauces : "&&order.sauces !== "Sauces :   ") && <DetailItem>{order.sauces }</DetailItem>} 
            {order.toppings.filter(t=>t.checked).length >=1&& <DetailItem>
              Supp:
                    {order.toppings
                    .filter(t=>t.checked)
                    .map(topping=>topping.name)
                    .join(", ")}
                    
                </DetailItem>}
                {order.remark && (
  <DetailItem>
    N.B: {order.remark}
  </DetailItem>
)}
                {order.choice && <DetailItem>{order.choice}</DetailItem>}
            </OrderContainer>))}

     
            <OrderContainer>
             
                <OrderItem>
                <div>{T("20",lang)+(discountValue ==0 ?  " ":discountType == "percentage" ?" "+discountValue+"%" : " -"+discountValue+" €") }</div>
                    <div/>
                    <Div>{!loggedIn && !(isGuest ||(session.getItem("GuestOk")&&loggedIn==null))  ?((discountType == "percentage" ?subtotal-(subtotal*(discountValue/100))+packagingFee+" €":subtotal-discountValue+packagingFee))+" €" :deliveryFee!==null ? ((discountType == "percentage" ?subtotal-subtotal*(discountValue/100)+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee:subtotal-discountValue+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee))+" €":T("7",lang)}</Div>
                    
                </OrderItem>
                <OrderItem>
               <div />
               <div />

                <Diam>{!loggedIn && !(isGuest || (session.getItem("GuestOk")&&loggedIn==null))  ?((discountType == "percentage" ?Math.round((subtotal-subtotal*(discountValue/100)+packagingFee)/changeRate)+" 💎":Math.round((subtotal-discountValue+packagingFee)/changeRate)))+" 💎" :deliveryFee!=null ? discountType == "percentage" ?Math.round((subtotal-subtotal*(discountValue/100)+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee)/changeRate)+" 💎":Math.round((subtotal-discountValue+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee)/changeRate)+" 💎":T("7",lang) }</Diam>
                </OrderItem>
            </OrderContainer>


            <Notice2>{message}</Notice2>


            <PromoCont>
                    
                    <PromoCode lang={lang}  code={code} setCode={setCode}  />
                    <Btn className="btn btn-info"  onClick={()=>(code == null || !code.trim().length > 0 )? setMessage(T("6",lang)):Discount()}>{T("22",lang)}</Btn>
                  
                 </PromoCont>
               
        </OrderContent>)
        
 

        }
        <DialogFooter>
      
          {<Hide>{startTime}</Hide>}
            {!(isAdmin||(canShowWork&& (working&&
             !interval ?
            (startTime<(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes())
            && endTime >(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes())):
            
            (startTime<(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes())
            && endTime >(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes()))
            ||(startTime2<(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes())
            && endTime2 >(((currentDateTime.getHours()>=0 && currentDateTime.getHours()<=9) ? "0"+currentDateTime.getHours(): currentDateTime.getHours())+":"+currentDateTime.getMinutes()))
            )))?
      
      
      
      <FooterContainer>{canShowWork==null ?"":canShowWork ==true? "":<Stripe><Text>{T("101",lang)}</Text></Stripe>}
        {canShowWork ?<Stripe> <TextFlex> { !interval ?T("4",lang)+startTime+T("5",lang)+endTime :T("4",lang)+startTime+T("5",lang)+endTime+"\n"+startTime2+T("5",lang)+endTime2 }</TextFlex></Stripe>: ""}
        </FooterContainer>  :(orders.length >0 && subtotal >=minOrderPrice)||isAdmin ? <ButtonOrderContainerH>
          {isActive&&isAdmin&&<XBtn onClick={()=>{
             setTotal((discountType == "percentage" ?subtotal-subtotal*(discountValue/100)+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee:subtotal-discountValue+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee));
           // setCrossSell(true);
            setOpenOrderDialog(true);
            setOrderType("phone");
        }}
          >☎️</XBtn>}


          
          <ConfirmButton className="btn" onClick={
            ()=>{
             
                if(loggedIn || session.getItem("GuestOk")==="true"){
                  console.log("this is happening")
                    setTotal((discountType == "percentage" ?subtotal-subtotal*(discountValue/100)+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee:subtotal-discountValue+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee));

                 
                   //setCrossSell(true);
                   setOrderType("order");
                   // orders.length >0
                   setOpenOrderDialog(true);
                 
                }
                else{
                  setIsGuest(false);
                  session.clear();
                   signIn_UpPanel ? setSignIn_UpPanel(false): setSignIn_UpPanel(true);
                }
            } 
        }>{T("21",lang)}
        
        </ConfirmButton>




        {isActive&&isAdmin&&<XBtn onClick={()=>{
           setTotal((discountType == "percentage" ?subtotal-subtotal*(discountValue/100)+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee:subtotal-discountValue+(deliveryFee-(deliveryFee*deliveryPromo))+packagingFee));
            //setCrossSell(true);
            setOpenOrderDialog(true);
            setOrderType("dine");
        }
        } className="btn">🍽️</XBtn>}</ButtonOrderContainerH> :<Open><Text>{T("3",lang)} </Text></Open>}
        </DialogFooter>
            
       </OrderStyled>
       </>
    );
}