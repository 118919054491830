import { computeHeadingLevel } from "@testing-library/react";
import React from "react";
import styled from "styled-components";
import {T} from "../Language/translate";
const InputContainer = styled.div`
  display: flex;
  flex-direction: row;
  margin:auto;
  margin-left:10px;
  @media(max-width: 700px){ 
   margin:auto;
    
   }
  
`;

const X = styled.div`
margin-right:10px;

width:200px;
`;

const Div = styled.div`
display:none;
`;
const Input = styled.input `
resize: none;

@media(max-width: 700px){ 
  
   
  }
`;
export function PromoCode({code,setCode,lang}){
    const handleChange = event => {
        setCode(event.target.value);

      };
    return(
        
        <InputContainer className="form-group">
       
        <X>
          <Input type="text" className="form-control" name="ingredient" placeholder={T("23",lang)}
            onChange={handleChange} 
            onBlur={handleChange}
            value={code}
            />
        </X>
       <Div>{code}</Div>
      </InputContainer>

    )
}