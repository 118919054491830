import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import Autocomplete from "react-google-autocomplete";
import { OrderBtn } from "../Styles/colors";
import useForm from "../Hooks/useForm";
import { T } from "../Language/translate";

const db = window.firebase.firestore();

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: auto;
  margin: 20px;
`;

export const UserFooter = styled.div`
  height: 60px;
  display: flex;
  margin-top: 10px;
  justify-content: center;
`;

export const ConfirmButton = styled.div`
  margin: 10px;
  color: white;
  height: 40px;
  width: 200px;
  border-radius: 5px;
  padding: 10px;
  text-align: center;
  cursor: pointer;
  background-color: ${OrderBtn};
  opacity: 0.7;
  z-index: 4;
  ${({ disabled }) =>
    disabled &&
    `
    opacity: .5;
    background-color: grey;
    pointer-events: none;
  `}
`;

const Notice = styled.span`
  color: red;
  font-size: 12px;
`;

const AddressTxt = styled.div`
  text-align: left;
  font-size: 14px;
  font-weight: bold;
  margin: auto;
`;

function phoneValid(value) {
  return !isNaN(value) && value != null && value.trim().length > 0;
}

function Required(value) {
  return value != null && value.trim().length > 0;
}

const session = window.sessionStorage;

export function UserForm({
  setOpenUser,
  loggedIn,
  prop,
  logout,
  setLogInPanel,
  lang,
  isGuest,
  setIsGuest,
  setOpenOrderDialog,
  setDeliveryFee,
  setMinOrderPrice,
  takeOrDelivery,
}) {
  const [zip, setZip] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [selected, setSelected] = useState(false);
  const [phone, setPhone] = useState("");
  const [hNumber, setHnumber] = useState("");
  // When a ZIP isn’t returned from Google we allow manual entry:
  const [zipManual, setZipManual] = useState(false);

  // A ref to help focus the Autocomplete input:
  const autocompleteContainerRef = useRef(null);

  const initialState = { name: "", address: "", floor: "", door: "" };
  const validations = [];
  const { values, errors, changeHandler } = useForm(initialState, validations, prop);
  const [Err, setError] = useState("");
  const [addressErr, setAddressErr] = useState("");
  const [zipErr, setZipErr] = useState("");
  const [phoneErr, setPhoneErr] = useState("");
  const [HnumErr, setHnumErr] = useState("");

  function showError() {
    if (errors.address || errors.phone) {
      setError(T("52", lang));
    }
    phoneValid(phone) ? setPhoneErr("") : setPhoneErr(T("48", lang));
    !Required(values.address) ? setAddressErr(T("59", lang)) : setAddressErr("");
    !Required(zip) ? setZipErr(T("47", lang)) : setZipErr("");
    !Required(hNumber) ? setHnumErr(T("139", lang)) : setHnumErr("");
    console.log(
      values.address &&
        (zipManual ? Required(zip) : !isNaN(zip)) &&
        phoneValid(phone) &&
        Required(hNumber)
    );
    console.log(hNumber);
  }

  // This function is called when a suggestion is selected.
  function handleAddition(place) {
    console.log("Test the addition");
    let newZip = "";
    let newStreet = "";
    let newCity = "";
    let newHNumber = "";

    place.address_components?.forEach((item) => {
      if (item.types.includes("postal_code")) newZip = item.long_name;
      if (item.types.includes("route")) newStreet = item.long_name;
      if (item.types.includes("locality")) newCity = item.long_name;
      if (item.types.includes("street_number")) newHNumber = item.long_name;
    });

    // Fallback if street or city are missing.
    if (!newStreet || !newCity) {
      const formattedParts = place.formatted_address.split(",");
      if (!newStreet && formattedParts.length > 1) newStreet = formattedParts[0].trim();
      if (!newCity && formattedParts.length > 2) newCity = formattedParts[1].trim();
    }
    console.log(newStreet);
    console.log(newCity);

    if (newZip) {
      setZip(newZip);
      setZipManual(false);
    } else {
      setZip("");
      setZipManual(true);
    }

    setStreet(newStreet);
    setCity(newCity);
    setHnumber(newHNumber ? newHNumber : hNumber);

    // Update the form state with the formatted address from Google.
    changeHandler({ target: { name: "address", value: place.formatted_address } });
    setSelected(true);
    setAddressErr("");
    setError("");
  }

  function SignOUt() {
    logout();
    setTimeout(() => {
      setOpenUser();
    }, 500);
    setLogInPanel(false);
  }

  const [points, setPoints] = useState();
  useEffect(() => {
    if (loggedIn) {
      db.collection("users")
        .doc(loggedIn.email)
        .get()
        .then((doc) => {
          setPoints(doc.data() ? doc.data().points : "10");
        });
    }
  }, [loggedIn]);

  useEffect(() => {
    // This effect ensures the autocomplete input is focused and
    // that the browser doesn’t autofill it.
    const timer = setTimeout(() => {
      if (autocompleteContainerRef.current) {
        const input = autocompleteContainerRef.current.querySelector("#my-autocomplete");
        if (input) {
          input.focus();
          input.setAttribute("autocomplete", "new-password");
        }
      }
    }, 1000);

    return () => clearTimeout(timer);
  }, []);

  const handleGuest = () => {
    session.setItem("name", Required(values.name) ? values.name : "Invité");
    session.setItem("houseNumber", hNumber);
    session.setItem("street", street);
    session.setItem("city", city);
    session.setItem("floor", values.floor);
    session.setItem("phone", phone);
    session.setItem("zipCode", zip);
    session.setItem("GuestOk", true);

    db.collection("zip")
      .doc(String(session.getItem("zipCode")))
      .get()
      .then((X) => {
        if (X.exists) {
          setDeliveryFee(X.data().price ? X.data().price : 0);
          setOpenUser();
        } else {
          setAddressErr(T("50", lang));
        }
      });

    const guestDocRef = db.collection("guests").doc(phone);
    try {
      guestDocRef.set({
        street: street,
        zipCode: zip,
        city: city,
        houseNumber: hNumber,
        name: values.name,
        phone: phone,
        floor: values.floor,
  
        date: new Date(),
        status: true,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleSubmit = () => {
    db.collection("zip")
      .doc(String(zip))
      .get()
      .then((doc) => {
        if (doc.exists) {
          setDeliveryFee(doc.data().price ? doc.data().price : 0);
          const docRef = db.collection("users").doc(loggedIn.email);
          try {
            docRef.set({
              street: street,
              zipCode: zip,
              city: city,
              houseNumber: hNumber,
              email: loggedIn.email,
              name: loggedIn.displayName,
              phone: phone,
              floor: values.floor,
            
              points: points,
              date: new Date(),
              status: true,
            });
          } catch (e) {
            console.log(e);
          }
          setOpenUser();
        } else {
          setAddressErr(T("50", lang));
        }
      });
  };

  return (
    <>
      <Notice>{zipErr}</Notice>
      <label>
        {T("37", lang)}
        <input
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
          className="form-control"
          name="zip"
          type="text"
          value={zip}
          onChange={(e) => setZip(e.target.value)}
          disabled={!zipManual} // Allow manual ZIP entry only if needed
        />
      </label>
      <Notice>{!(errors.address || errors.zip || errors.phone) ? Err : addressErr}</Notice>
      <label className="label">
        <AddressTxt>{T("142", lang)}</AddressTxt>
        <Notice>{addressErr}</Notice>
        <div ref={autocompleteContainerRef}>
          <Autocomplete
            id="my-autocomplete"
            type="text"
            placeholder=" "
            className="form-control"
            defaultValue={values.address}
            name="address"
            apiKey={"AIzaSyC_NsDrw_bCL2e5UQEiknoOFhwWdKadKtQ"}
            style={{ width: "100%" }}
            onPlaceSelected={handleAddition}
            options={{
              types: ["address"],
              componentRestrictions: { country: "fr" },
              fields: ["address_components", "formatted_address"],
            }}
            autoFocus
          />
        </div>
      </label>

      <Notice>{HnumErr}</Notice>
      <label>
        {T("138", lang)}
        <input
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
          className="form-control"
          name="Hnumber"
          type="text"
          value={hNumber}
          onChange={(e) => setHnumber(e.target.value)}
        />
      </label>
      <label>
        {T("39", lang)}
        <input
          autoComplete="off"
          spellCheck="false"
          autoCorrect="off"
          className="form-control"
          name="floor"
          type="text"
          value={values.floor}
          onChange={changeHandler}
        />
      </label>

      {(isGuest || (session.getItem("GuestOk") && loggedIn == null)) && (
        <label>
          {T("34", lang)}
          <input
            autoComplete="off"
            spellCheck="false"
            autoCorrect="off"
            className="form-control"
            name="name"
            type="text"
            value={values.name}
            onChange={changeHandler}
          />
        </label>
      )}
      <Notice>{phoneErr}</Notice>
      <label>
        {T("54", lang)}
        <input
          autoComplete="new-password"
          aria-autocomplete="both"
          aria-owns="suggestions"
          placeholder=" "
          spellCheck="false"
          aria-haspopup="listbox"
          className="form-control"
          name="phone"
          type="text"
          value={phone}
          onChange={(e) => setPhone(e.target.value)}
        />
      </label>
      <UserFooter>
        <ConfirmButton
          onClick={() => {
            !(values.address &&
            Required(zip) &&
            phoneValid(phone) &&
            Required(hNumber))
              ? showError()
              : isGuest
              ? handleGuest()
              : handleSubmit();
          }}
        >
          {!isGuest ? T("55", lang) : T("130", lang)}
        </ConfirmButton>
        {!isGuest && <ConfirmButton onClick={SignOUt}>{T("32", lang)}</ConfirmButton>}
      </UserFooter>
    </>
  );
}
