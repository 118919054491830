import React from "react";
import styled from 'styled-components';
import { UserHeader } from "../userDialog/userDialog";
import { UserFooter } from "../userDialog/userForm";
import { ConfirmButton } from "../userDialog/userForm";
import { useState } from "react";
import { useEffect } from "react";
import {T} from "../Language/translate";
const Dialog = styled.div`
position: fixed;
  width: 500px;
  height:auto;
  background-color: white;
  margin-bottom:50px;
  z-index: 57 !important;
  margin:auto;
  padding:10px;
  display: flex;
  flex-direction: column;
  border-radius:5px;
  // Add this part
  @media (max-width: 600px) {
    
    overflow-y: scroll;
    height:auto;
    width: auto;
 
    
  }
`;

 const Wrapper = styled.div`
z-index: 57 !important;
position:fixed;
width:100%;
height:100%;
left:0;
bottom:0;
  display: flex;
  justify-content: center;
  align-items: center;

`;

const DialogShadow = styled.div`
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0px;
  left:0px;
  background-color: black;
  opacity: 0.7;
  z-index: 56 !important;
`;
const ProfileContainer = styled.div`
width: 80%;
height:60%;
margin-left:auto;
margin-right:auto;
margin-top:20px;
border-radius:5px;
border:1px solid;
border-color:gray;
display :flex;
align-items:center;
justify-element:center;
flex-direction:column; 

`;
export const Close = styled.div`
height: 30px;
width: 30px;
display :flex;
align-items:center;
justify-element:center;
position:absolute;
background-image:url("/img/close.png");
background-position: center;
background-repeat: no-repeat;
background-size: cover;
border: none; 
margin:10px;
top:0;
right:0;
font-size: 18px;
font-weight:bold;
 &:hover{
    cursor: pointer;
    opacity:0.7;
 }
`;
const Data = styled.div`
display:flex;
text-align:center;
flex-direction:row;
justify-items:center;
align-content:center;
margin: 1px;
font-weight:bold;
`;


const db = window.firebase.firestore();
var session = window.sessionStorage;
function ProfileDialogContainer({profile, setProfile, loggedIn,setEdit,lang}){
     
    const [data , setData] = useState();
    useEffect(()=>{ 
     
      if(loggedIn){
        let isMounted = true;  
        db.collection('users').doc(loggedIn.email).get().then((doc)=>{ if(isMounted) return setData(doc.data())})
        return () => { isMounted = false };
      }
   
},[]);
    function EditUser(){
      setEdit(data);
      setProfile();

    }
    function EditGuest(){
      setEdit({
        name: session.getItem("name") ,
        address : session.getItem("address"),
        floor : session.getItem("floor"),
        door : session.getItem("door"),
        zip : session.getItem("zip"),
        phone : session.getItem("phone")
      });
      setProfile();
    }
 
    return (
        <>
        <DialogShadow  />
        <Wrapper>
        <Dialog>
        <UserHeader>{T("33",lang)+profile}</UserHeader>
        <Close onClick={()=>{setProfile()}}/>
        <UserHeader>{T("42",lang)}  </UserHeader>
        <ProfileContainer>
       {(session.getItem("GuestOk")&&loggedIn==null) ? <>
       <Data>{T("34",lang) + session.getItem("name") }</Data> 
       <Data>{session.getItem("address")?T("36",lang) + session.getItem("address")  :  T("36",lang) + session.getItem("city")+" "+session.getItem("street") }</Data>
       <Data>{T("138",lang) + session.getItem("houseNumber") }</Data>  
       <Data>{T("39",lang) + session.getItem("floor") }</Data>
       <Data>{T("40",lang) + session.getItem("door") }</Data> 
       <Data>{T("38",lang) + session.getItem("phone") }</Data> 
       </>: <>
       {data ? <Data>{T("34",lang) + data.name}</Data> : <Data>Loading...</Data>}
        {data ? <Data>{T("35",lang)  + data.email}</Data>: <Data>Loading...</Data>} 
        {data ? <Data>{ data.address ? T("36",lang)+ data.address : T("36",lang)  + data.street+" "+data.city}</Data>: <Data>Loading...</Data>}
        {data ? <Data>{T("37",lang)  +(data.zipCode? data.zipCode: data.zip)}</Data>: <Data>Loading...</Data>}
        {data ? <Data>{T("138",lang)  +" : "+ (data.houseNumber ?data.houseNumber:data.buildingNumber)}</Data>: <Data>Loading...</Data>}
        {data ? <Data>{T("38",lang)+  data.phone}</Data>: <Data>Loading...</Data>}
        {data ? <Data>{T("39",lang)+ data.floor}</Data>: <Data>Loading...</Data>}
        {data ? <Data>{T("41",lang) + Math.floor(data.points)}💎</Data>: <Data>Loading...</Data>}
       </>}
     
        </ProfileContainer>
        
        <UserFooter>
        <ConfirmButton onClick={function(event){(session.getItem("GuestOk")&&loggedIn==null)? EditGuest(): EditUser() }}>{T("43",lang)}</ConfirmButton>
      </UserFooter>
        </Dialog>
        </Wrapper>
        </>
    )

}



export function ProfileDialog(props){
    if (!props.profile) return null;
    return <ProfileDialogContainer {...props}/>
}